import React from 'react'
import { Categories } from './categories'
import { Sessions } from './sessions'

export function Sidebar () {
  return (
    <>
      <div className='mb-[56px]'>
        <Sessions />
      </div>
      <div>
        <Categories />
      </div>
    </>
  )
}
