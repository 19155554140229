import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import Uppy from '@uppy/core'
// import RemoteSources from '@uppy/remote-sources'
import { DashboardModal } from '@uppy/react'
import FirebaseStorageUpload from '../../../utils/FirebaseStorageUpload'
import { customFetch } from '../../../utils'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './importFiles.css'
import { useNavigate } from 'react-router-dom'

export function ImportFiles ({ projectId, projectName, handleClose, setMeetings, limitFiles, meetingId }) {
  const navigate = useNavigate()
  const [uppy] = useState(() => new Uppy({
    autoProceed: false,
    debug: true,
    restrictions: {
      maxFileSize: 10 * 1024 * 1024 * 1024, // 10 GB (expressed in Bytes)
      maxNumberOfFiles: limitFiles || 10
    }
  })
    // .use(RemoteSources, { companionUrl: `${process.env.REACT_APP_COMPANION_URL}/companion`, sources: ['GoogleDrive', 'Box', 'Dropbox', 'OneDrive'] })
    .use(FirebaseStorageUpload))

  useEffect(() => {
    uppy.on('complete', async (result) => {
      try {
        console.log('Successful uploads:', result.successful)
        if (result.successful.length) {
          const files = []
          result.successful.forEach(file => {
            files.push({ url: file.uploadURL, fileName: file.name, size: file.size, storedFileName: file.storedFileName, duration: file.duration, type: file.type })
          })
          if (!files.length) return

          const start = Date.now()
          const res = await customFetch('/createNotesFromFiles', 'POST', { projectId, projectName, files: JSON.stringify(files), meetingId })
          if (res.error) {
            toast.error(res.error.error.message)
            handleClose()
            return
          }
          if (!res.meetings.length) {
            toast.error('Whoops! Something went wrong. Please try again.')
            handleClose()
            return
          }

          toast.success('Files uploaded! We’ll let you know when the notes are ready. You may close your browser.', { duration: 3000 })
          // the sorting is added just in case there is an event with a future meet that should go first
          if (setMeetings && !meetingId) {
            setMeetings(prevMeetings => [...res.meetings, ...prevMeetings].sort((a, b) => {
              let timeA = a.created_at
              let timeB = b.created_at
              if (a.start) {
                timeA = new Date(a.start).getTime()
              }
              if (b.start) {
                timeB = new Date(b.start).getTime()
              }
              return (timeA < timeB) ? 1 : -1
            }))
          }
          if (setMeetings && meetingId) {
            setMeetings(prevMeetings => prevMeetings.map(meet => meet.id === meetingId ? res.meetings[0] : meet).sort((a, b) => {
              let timeA = a.created_at
              let timeB = b.created_at
              if (a.start) {
                timeA = new Date(a.start).getTime()
              }
              if (b.start) {
                timeB = new Date(b.start).getTime()
              }
              return (timeA < timeB) ? 1 : -1
            }))
          }
          const elapsed = Date.now() - start
          if (elapsed < 3000) {
            await new Promise(resolve => setTimeout(resolve, 3000 - elapsed))
          }
          navigate(`/project/${projectId}`)
          handleClose()
        }
      } catch (error) {
        console.error('Error uploading files', error)
        toast.error('Whoops! Something went wrong. Please try again.')
        handleClose()
      }
    })

    return () => uppy.close({ reason: 'unmount' })
  }, [])

  return (
    <DashboardModal
      uppy={uppy}
      open={true}
      target={document.body}
      note="Upload up to 10 files, each up to 10GB. Acceptable file types: MP3, MP4, MP2, AAC, WAV, FLAC, PCM, M4A, Ogg, Opus, WebM."
      // proudlyDisplayPoweredByUppy={false}
      // plugins={['GoogleDrive', 'Box', 'Dropbox', 'OneDrive']}
      onRequestClose={() => handleClose(false)}
    />
  )
}

ImportFiles.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  setMeetings: PropTypes.func,
  limitFiles: PropTypes.number,
  meetingId: PropTypes.string
}
