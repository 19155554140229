import React, { useContext, useEffect } from 'react'
import { Sidebar } from './sidebar'
import { Topbar } from './topbar'
import { Projects } from './projects'
import { Events } from './events'
import { Team } from './team'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { customFetch } from '../../utils'
import { AuthContext } from '../../context/AuthContext'
import { DashboardContext } from '../../context/DashboardContext'
import { Meetings } from './meetings'
import { Integrations } from './integrations'

export function Dashboard () {
  const [searchParams] = useSearchParams()
  const { menu } = useParams()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const { refreshActiveWorkspace, activeWorkspace } = useContext(DashboardContext)

  const setMenu = (newMenu) => navigate(`/dashboard/${newMenu}`)

  const handleSubscribeToPlan = async (workspaceId, planId) => {
    try {
      const response = await customFetch('/createCheckoutUrl', 'POST', { workspaceId, planId })
      if (!response.hostedPageUrl) throw new Error('No plan URL found')
      window.location.href = response.hostedPageUrl
    } catch (error) {
      console.error('Error initiating payment', error)
    }
  }

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    if (!activeWorkspace) return
    const paymentStatus = searchParams.get('payment_status')
    if (paymentStatus === 'success') return
    const planId = searchParams.get('plan_id')
    if (!planId) return
    const workspacesAmount = Object.keys(user.workspaces).length
    if (workspacesAmount !== 1) return
    if (activeWorkspace.plan_id) {
      navigate(`/workspace/select?plan_id=${planId}`)
    } else {
      handleSubscribeToPlan(Object.keys(user.workspaces)[0], planId)
    }
  }, [activeWorkspace])

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    // check if this is a redirect from the payment gateway
    const paymentStatus = searchParams.get('payment_status')
    const planId = searchParams.get('plan_id')
    if (paymentStatus === 'success') {
      toast.success('Payment successful, might take a few minutes to reflect in your account')
      setTimeout(refreshActiveWorkspace, 30000)
      return
    }
    if (planId) {
      const workspacesAmount = Object.keys(user.workspaces).length
      if (workspacesAmount === 0) {
        navigate(`/workspace/create?plan_id=${planId}`)
      } else if (workspacesAmount > 1) {
        navigate(`/workspace/select?plan_id=${planId}`)
      }
    }
  }, [user.workspaces])

  return (
      <div className='h-screen bg-white'>
        <div className='h-full w-60 fixed top-0 border-r-2 border-grey-200 overflow-auto hideScrollbar'>
          <Sidebar menu={menu} setMenu={setMenu} />
        </div>
        <div className='h-[70px] fixed top-0 left-60 border-b-2 border-grey-200 z-10' style={{ width: 'calc(100% - 240px)' }}>
          <Topbar />
        </div>
        <div className='pl-12 pb-12 fixed top-[70px] left-60 border-b-2 border-grey-200 bg-grey-100 overflow-auto hideScrollbar z-0' style={{ width: 'calc(100% - 240px)', height: 'calc(100% - 70px)' }}>
          {menu === 'home' && (
            <>
              <Events limit={10} />
              <Projects limit={10} />
            </>
          )}
          {menu === 'projects' && <Projects />}
          {menu === 'meetings' && <Meetings />}
          {menu === 'integrations' && <Integrations />}
          {menu === 'team' && <Team />}
        </div>
      </div>
  )
}
