import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { AuthContext } from './AuthContext'

export const DashboardContext = createContext()

export const DashboardContextProvider = ({ children }) => {
  const [allProjects, setAllProjects] = useState([])
  const [meetings, setMeetings] = useState([])
  const [meetingsOverview, setMeetingsOverview] = useState({}) // { meetingId: { background: '', validation: {...}, ... } }
  const [filteredProjects, setFilteredProjects] = useState([])
  const [projectsLoading, setProjectsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [workspacesLoading, setWorkspacesLoading] = useState(true)
  const [workspaces, setWorkspaces] = useState({}) // { workspaceId: workspaceName }
  const [activeWorkspace, setActiveWorkspace] = useState({}) // { id: workspaceId, name: workspaceName, sub_id: '',  ... }
  const { user } = useContext(AuthContext)

  function filterProjects (term) {
    setFilteredProjects(allProjects.filter(project => project.name.toLowerCase().includes(term.toLowerCase())))
  }

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    setProjects()
    initWorkspaces()
  }, [user])

  async function setProjects () {
    setProjectsLoading(true)
    const q = query(collection(db, 'projects'), where('workspace_id', '==', user.active_workspace))
    const docRefs = await getDocs(q)

    if (!docRefs.empty) {
      const projectRes = []

      docRefs.forEach(project => {
        projectRes.push({
          id: project.id,
          ...project.data()
        })
      })

      await initMeetings(projectRes.map(project => project.id))

      projectRes.sort((a, b) => a.created_at - b.created_at > 0 ? -1 : 1)
      setAllProjects(projectRes)
      setFilteredProjects(projectRes.filter(project => project.name.toLowerCase().includes(searchTerm.toLowerCase())))
    }
    setProjectsLoading(false)

    const docRef = doc(db, 'workspaces', user.active_workspace)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      setActiveWorkspace({ id: docSnap.id, ...docSnap.data() })
    }
  }

  async function initMeetings (projectIds) {
    // get meetings data
    if (projectIds.length > 30) projectIds = projectIds.slice(0, 30)
    let q = query(collection(db, 'meetings'), where('project_id', 'in', projectIds))
    let querySnapshot = await getDocs(q)
    if (querySnapshot.empty) return

    const newMeets = []
    querySnapshot.forEach((doc) => {
      newMeets.push({ ...doc.data(), id: doc.id })
    })
    newMeets.sort((a, b) => {
      let timeA = a.created_at
      let timeB = b.created_at
      if (a.start) {
        timeA = new Date(a.start).getTime()
      }
      if (b.start) {
        timeB = new Date(b.start).getTime()
      }
      return (timeA < timeB) ? 1 : -1
    })
    setMeetings(newMeets)

    // get meetings overview
    const meetingsOverview = {}
    for (let i = 0; i < newMeets.length; i += 30) {
      const end = i + 30 < newMeets.length ? i + 30 : newMeets.length
      q = query(collection(db, 'meetingsOverview'), where('__name__', 'in', newMeets.slice(i, end).map(meet => meet.id)))
      querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          meetingsOverview[doc.id] = doc.data()
        })
      }
    }
    setMeetingsOverview(meetingsOverview)
  }

  async function initWorkspaces () {
    setWorkspacesLoading(true)

    const documents = {}
    for (const id of Object.keys(user.workspaces)) {
      const workspace = await getDoc(doc(db, 'workspaces', id))
      if (workspace.exists()) {
        documents[workspace.id] = { ...workspace.data() }
      } else {
        console.log('No document found with id:', id)
      }
    }

    setWorkspaces(documents)
    setWorkspacesLoading(false)
  }

  async function refreshActiveWorkspace () {
    console.log('Refreshing active workspace', user.active_workspace)
    const docRef = doc(db, 'workspaces', user.active_workspace)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      console.log({ id: docSnap.id, ...docSnap.data() })
      setActiveWorkspace({ id: docSnap.id, ...docSnap.data() })
    }
  }

  return (
    <DashboardContext.Provider value={{
      filteredProjects,
      setFilteredProjects,
      filterProjects,
      allProjects,
      setAllProjects,
      meetings,
      meetingsOverview,
      searchTerm,
      setSearchTerm,
      projectsLoading,
      workspacesLoading,
      workspaces,
      activeWorkspace,
      setActiveWorkspace,
      refreshActiveWorkspace
    }}>
      {children}
    </DashboardContext.Provider>
  )
}

DashboardContextProvider.propTypes = {
  children: PropTypes.node
}
