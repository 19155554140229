import React, { useContext } from 'react'
import { ProjectContext } from '../../context/ProjectContext'

export function Menu () {
  const {
    groupNotesBy,
    setGroupNotesBy,
    setScrollToCategory,
    setScrollToMeeting,
    notes,
    meetings,
    overview,
    menu,
    setMenu
  } = useContext(ProjectContext)

  return (
    <div className="flex justify-between items-center my-[30px]">
      <div className='flex items-center shadow'>
        {meetings.length > 0 && !!Object.keys(overview).length && !!overview.background && !!overview.feedbacks?.length && !!overview.problems?.length && !!overview.qas?.length && !!overview.quotes?.length && (
          <button
            className={`bg-white h-12 px-5 border rounded-tl-md rounded-bl-md flex items-center gap-4 ${menu === 'overview' ? 'border-pink' : 'border-grey-200'}`}
            onClick={() => {
              setScrollToCategory('')
              setScrollToMeeting('')
              setMenu('overview')
            }}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3585_5560)">
              <path d="M8.33203 10.8327C8.33203 11.2747 8.50763 11.6986 8.82019 12.0112C9.13275 12.3238 9.55667 12.4993 9.9987 12.4993C10.4407 12.4993 10.8646 12.3238 11.1772 12.0112C11.4898 11.6986 11.6654 11.2747 11.6654 10.8327C11.6654 10.3907 11.4898 9.96673 11.1772 9.65417C10.8646 9.34161 10.4407 9.16602 9.9987 9.16602C9.55667 9.16602 9.13275 9.34161 8.82019 9.65417C8.50763 9.96673 8.33203 10.3907 8.33203 10.8327Z" stroke={menu === 'overview' ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M11.207 9.62435L12.9154 7.91602" stroke={menu === 'overview' ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5.33333 16.6662C4.11512 15.698 3.22826 14.3747 2.79565 12.8799C2.36304 11.3851 2.4061 9.7928 2.91887 8.32356C3.43164 6.85433 4.38873 5.58098 5.65749 4.67997C6.92625 3.77897 8.44386 3.29492 10 3.29492C11.5561 3.29492 13.0737 3.77897 14.3425 4.67997C15.6113 5.58098 16.5684 6.85433 17.0811 8.32356C17.5939 9.7928 17.637 11.3851 17.2044 12.8799C16.7717 14.3747 15.8849 15.698 14.6667 16.6662H5.33333Z" stroke={menu === 'overview' ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3585_5560">
              <rect width="20" height="20" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <div className="text-black text-base font-bold font-['Manrope'] leading-tight">Overview</div>
            {/* <div className="w-[31px] p-2 bg-pink-500 rounded-[30px] justify-center items-center gap-1 flex"> */}
          </button>
        )}
        <button
          className={`bg-white h-12 px-5 border ${meetings.length === 0 && 'rounded-tl-md rounded-bl-md'} flex items-center gap-4 ${menu === 'notes' ? 'border-pink' : 'border-grey-200'}`}
          onClick={() => {
            setScrollToCategory('')
            setScrollToMeeting('')
            setMenu('notes')
          }}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3944_11561)">
            <path d="M5.00065 3.33398H15.0007C15.4427 3.33398 15.8666 3.50958 16.1792 3.82214C16.4917 4.1347 16.6673 4.55862 16.6673 5.00065V10.834H12.5007C12.0586 10.834 11.6347 11.0096 11.3221 11.3221C11.0096 11.6347 10.834 12.0586 10.834 12.5007V16.6673H5.00065C4.55862 16.6673 4.1347 16.4917 3.82214 16.1792C3.50958 15.8666 3.33398 15.4427 3.33398 15.0007V5.00065C3.33398 4.55862 3.50958 4.1347 3.82214 3.82214C4.1347 3.50958 4.55862 3.33398 5.00065 3.33398Z" stroke={menu === 'notes' ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.6673 10.834V10.9773C16.6672 11.4193 16.4916 11.8432 16.179 12.1557L12.1557 16.179C11.8432 16.4916 11.4193 16.6672 10.9773 16.6673H10.834" stroke={menu === 'notes' ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_3944_11561">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          <div className="text-black text-base font-bold font-['Manrope'] leading-tight">Needs</div>
          {/* <div className="w-[31px] p-2 bg-pink-500 rounded-[30px] justify-center items-center gap-1 flex"> */}
          <div className={`p-2 rounded-[30px] justify-center items-center gap-1 flex ${menu === 'notes' ? 'bg-pink' : 'bg-slate'}`}>
            <p className="text-white text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">{notes.length}</p>
          </div>
        </button>
        <button
          className={`bg-white h-12 px-5 border rounded-tr-md rounded-br-md flex items-center gap-4 ${menu === 'transcripts' ? 'border-pink' : 'border-grey-200'}`}
          onClick={() => {
            setScrollToCategory('')
            setScrollToMeeting('')
            setMenu('transcripts')
          }}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2272_3954)">
            <path d="M14.1667 16.6673H5C4.33696 16.6673 3.70107 16.4039 3.23223 15.9351C2.76339 15.4662 2.5 14.8304 2.5 14.1673C2.5 13.5043 2.76339 12.8684 3.23223 12.3996C3.70107 11.9307 4.33696 11.6673 5 11.6673H14.1667C13.5036 11.6673 12.8677 11.9307 12.3989 12.3996C11.9301 12.8684 11.6667 13.5043 11.6667 14.1673C11.6667 14.8304 11.9301 15.4662 12.3989 15.9351C12.8677 16.4039 13.5036 16.6673 14.1667 16.6673ZM14.1667 16.6673H15C15.663 16.6673 16.2989 16.4039 16.7678 15.9351C17.2366 15.4662 17.5 14.8304 17.5 14.1673V5.00065C17.5 4.55862 17.3244 4.1347 17.0118 3.82214C16.6993 3.50958 16.2754 3.33398 15.8333 3.33398H7.5C7.05797 3.33398 6.63405 3.50958 6.32149 3.82214C6.00893 4.1347 5.83333 4.55862 5.83333 5.00065V11.6673" stroke={menu === 'transcript' ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_2272_3954">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          <div className="text-black text-base font-bold font-['Manrope'] leading-tight">Transcripts</div>
          <div className={`p-2 rounded-[30px] justify-center items-center gap-1 flex ${menu === 'transcripts' ? 'bg-pink' : 'bg-slate'}`}>
            <p className="text-white text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">{meetings.length}</p>
          </div>
        </button>
      </div>

      <div className="h-10 bg-white rounded-[5px] shadow flex items-center">
        <div className={`mx-2 cursor-pointer ${groupNotesBy === 'meeting' && 'bg-lightPink2'}`} onClick={() => {
          setScrollToCategory('')
          setScrollToMeeting('')
          setGroupNotesBy('meeting')
          setMenu('notes')
        }}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1287_4882)">
            <path d="M10.4998 4.66602H5.83317C5.18884 4.66602 4.6665 5.18835 4.6665 5.83268V10.4993C4.6665 11.1437 5.18884 11.666 5.83317 11.666H10.4998C11.1442 11.666 11.6665 11.1437 11.6665 10.4993V5.83268C11.6665 5.18835 11.1442 4.66602 10.4998 4.66602Z" stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.1668 4.66602H17.5002C16.8558 4.66602 16.3335 5.18835 16.3335 5.83268V10.4993C16.3335 11.1437 16.8558 11.666 17.5002 11.666H22.1668C22.8112 11.666 23.3335 11.1437 23.3335 10.4993V5.83268C23.3335 5.18835 22.8112 4.66602 22.1668 4.66602Z" stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.4998 16.334H5.83317C5.18884 16.334 4.6665 16.8563 4.6665 17.5007V22.1673C4.6665 22.8116 5.18884 23.334 5.83317 23.334H10.4998C11.1442 23.334 11.6665 22.8116 11.6665 22.1673V17.5007C11.6665 16.8563 11.1442 16.334 10.4998 16.334Z" stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.1668 16.334H17.5002C16.8558 16.334 16.3335 16.8563 16.3335 17.5007V22.1673C16.3335 22.8116 16.8558 23.334 17.5002 23.334H22.1668C22.8112 23.334 23.3335 22.8116 23.3335 22.1673V17.5007C23.3335 16.8563 22.8112 16.334 22.1668 16.334Z" stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1287_4882">
            <rect width="28" height="28" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div className={`mr-2 cursor-pointer ${groupNotesBy === 'category' && 'bg-lightPink2'}`} onClick={() => {
          setScrollToCategory('')
          setScrollToMeeting('')
          setGroupNotesBy('category')
          setMenu('notes')
        }}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1287_4888)">
            <path d="M9.33317 4.66602H6.99984C5.71117 4.66602 4.6665 5.71068 4.6665 6.99935V20.9993C4.6665 22.288 5.71117 23.3327 6.99984 23.3327H9.33317C10.6218 23.3327 11.6665 22.288 11.6665 20.9993V6.99935C11.6665 5.71068 10.6218 4.66602 9.33317 4.66602Z" stroke={groupNotesBy === 'category' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.0002 4.66602H18.6668C17.3782 4.66602 16.3335 5.71068 16.3335 6.99935V13.9993C16.3335 15.288 17.3782 16.3327 18.6668 16.3327H21.0002C22.2888 16.3327 23.3335 15.288 23.3335 13.9993V6.99935C23.3335 5.71068 22.2888 4.66602 21.0002 4.66602Z" stroke={groupNotesBy === 'category' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1287_4888">
            <rect width="28" height="28" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        {/* <div className={`mx-2 cursor-pointer ${groupNotesBy === 'notImplemented' && 'bg-lightPink2'}`}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1287_4874)">
            <path d="M20.9998 4.66602H6.99984C5.71117 4.66602 4.6665 5.71068 4.6665 6.99935V20.9993C4.6665 22.288 5.71117 23.3327 6.99984 23.3327H20.9998C22.2885 23.3327 23.3332 22.288 23.3332 20.9993V6.99935C23.3332 5.71068 22.2885 4.66602 20.9998 4.66602Z" stroke={groupNotesBy === 'notImplemented' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.6665 11.666H23.3332" stroke={groupNotesBy === 'notImplemented' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.6665 4.66602V23.3327" stroke={groupNotesBy === 'notImplemented' ? '#FF4A8E' : 'black'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1287_4874">
            <rect width="28" height="28" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div> */}
      </div>
    </div>
  )
}
