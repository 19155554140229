/* eslint-disable react/no-unknown-property */
import React, { useCallback, useContext } from 'react'
import { Topbar } from './topbar'
import { Menu } from './menu'
import { Transcripts } from './transcripts'
import { Sidebar } from './sidebar'
import { ProjectContext } from '../../context/ProjectContext'
import { StickyGrid } from './stickyGrid'
import { NotesInput } from './notesInput'
import { Overview } from './overview'
import { OverviewSidebar } from './overview/sidebar'

export function Project () {
  const { isLoading, groupNotesBy, setScrollTopContainer, menu } = useContext(ProjectContext)

  const setContainerRef = useCallback((node) => {
    if (node !== null) {
      setScrollTopContainer(node)
    }
  }, [setScrollTopContainer])

  if (isLoading) {
    return (
      <div className="min-h-screen bg-grey-100">
        <div className='w-full fixed top-0 bg-white'>
          <Topbar />
        </div>
        <div className="h-full pt-40 flex justify-center items-center bg-grey-100">
            <svg className='w-40 h-40' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>
        </div>
      </div>
    )
  }

  return (
    <div className="h-screen bg-grey-100">
      <div className='w-full fixed top-0 bg-white'>
        <Topbar />
      </div>
      <div className='w-full fixed top-[80px] px-10'>
        <Menu />
      </div>
      {menu === 'overview' && (
        <>
        <div
          // width: '100% - 50px (margin left) - 50px (margin right) - 372px (sidebar) - 56px (gap to sidebar)'
          style={{ height: 'calc(100% - 188px)', width: 'calc(100% - 50px - 50px - 372px - 56px)' }}
          className='fixed top-[188px] left-[50px] mb-10 p-6 bg-white rounded-t-2xl shadow flex justify-center overflow-auto hideScrollbar'
        >
          <Overview />
        </div>
        <aside
          // height: 100% - 48px(padding topbar) - 32px(topbar) - 48px(menu) - 60px(margin menu)
          style={{ height: 'calc(100% - 188px)' }}
          className='w-[372px] h-[331px] fixed top-[188px] right-[50px] p-7 bg-white rounded-2xl shadow overflow-auto hideScrollbar'
        >
          <OverviewSidebar />
        </aside>
        </>
      )}
      {(menu === 'notes' || menu === 'transcripts') && (
        <>
        <aside
          // height: 100% - 48px(padding topbar) - 32px(topbar) - 48px(menu) - 60px(margin menu)
          style={{ height: 'calc(100% - 188px)' }}
          className='w-[372px] fixed top-[188px] mx-10 p-6 bg-white rounded-t-2xl shadow overflow-auto hideScrollbar'
        >
          <Sidebar />
        </aside>
        <div
          ref={setContainerRef}
          // width = 100% - 372px(sidebar) - 40px*2(margin sidebar) - 40px(margin right)
          // height: 100% - 80px(topbar) - 108px(menu) - 96px(notes input)
          style={menu === 'notes' && groupNotesBy === 'category'
            ? { height: 'calc(100% - 284px)', width: 'calc(100% - 412px)', right: 0 }
            : { height: 'calc(100% - 284px)', width: 'calc(100% - 492px', right: '40px' }}
          className='fixed top-[188px] overflow-auto hideScrollbar'
        >
          {menu === 'notes' ? <StickyGrid groupNotesBy={groupNotesBy} /> : <Transcripts />}
        </div>
        <div
          style={{ width: 'calc(100% - 372px - 40px' }}
          className='h-24 fixed bottom-0 right-0'
        >
          <NotesInput />
        </div>
        </>
      )}
    </div>
  )
}
