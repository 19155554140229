/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MeetingsContext } from '../../../context/MeetingContext'
import { Needs } from './needs'
import { Quotes } from './quotes'
import { QAs } from './qas'
import { Feedbacks } from './feedbacks'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../../firebase'
import ReactPlayer from 'react-player'
import thumbnailAudioDefault from '../../../assets/audio-thumbnail.png'
import thumbnailDefault from '../../../assets/video-player-box.png'
import { Timeline } from './timeline'
import { Problems } from './problems'

export function Canvas () {
  const [url, setUrl] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [clickedPreview, setClickedPreview] = useState(false)
  const {
    meeting,
    setMeeting,
    problemsRef,
    quotesRef,
    needsRef,
    feedbackRef,
    overviewRef,
    setContainerRef
  } = useContext(MeetingsContext)
  const nameRef = useRef()

  useEffect(() => {
    if (!meeting.stored_name) return
    const getFileUrl = async (filePath) => {
      try {
        const storageRef = ref(storage, `meets/${filePath}`)
        const url = await getDownloadURL(storageRef)
        setUrl(url)
        if (meeting.thumbnail) {
          const storageRef = ref(storage, `thumbs/${meeting.thumbnail}`)
          const url = await getDownloadURL(storageRef)
          setThumbnail(url)
        }
      } catch (error) {
        console.error('Error getting file URL:', error)
        setUrl(null)
      }
    }
    getFileUrl(meeting.stored_name)
  }, [meeting.stored_name])

  return (
    <div className="bg-white pt-[30px] h-full overflow-auto hideScrollbar flex justify-center" ref={setContainerRef}>
      <div className='w-[720px] flex flex-col gap-[36px]'>
        <div className='flex items-center justify-between' ref={overviewRef}>
          <input
              ref={nameRef}
              type='text'
              className="ml-4 text-[#3f424a] bg-white text-[26px] font-semibold font-['Manrope'] leading-[29.90px] grow"
              value={meeting.name}
              onChange={(e) => setMeeting({ ...meeting, name: e.target.value })}
              onKeyDown={e => e.key === 'Enter' && nameRef.current.blur()}
          />
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4007_32977)">
            <path d="M18.6667 21.0013C19.2855 21.0013 19.879 21.2471 20.3166 21.6847C20.7542 22.1223 21 22.7158 21 23.3346C21 22.7158 21.2458 22.1223 21.6834 21.6847C22.121 21.2471 22.7145 21.0013 23.3333 21.0013C22.7145 21.0013 22.121 20.7555 21.6834 20.3179C21.2458 19.8803 21 19.2868 21 18.668C21 19.2868 20.7542 19.8803 20.3166 20.3179C19.879 20.7555 19.2855 21.0013 18.6667 21.0013ZM18.6667 7.0013C19.2855 7.0013 19.879 7.24713 20.3166 7.68472C20.7542 8.1223 21 8.7158 21 9.33463C21 8.7158 21.2458 8.1223 21.6834 7.68472C22.121 7.24713 22.7145 7.0013 23.3333 7.0013C22.7145 7.0013 22.121 6.75547 21.6834 6.31788C21.2458 5.8803 21 5.28681 21 4.66797C21 5.28681 20.7542 5.8803 20.3166 6.31788C19.879 6.75547 19.2855 7.0013 18.6667 7.0013ZM10.5 21.0013C10.5 19.1448 11.2375 17.3643 12.5503 16.0516C13.863 14.7388 15.6435 14.0013 17.5 14.0013C15.6435 14.0013 13.863 13.2638 12.5503 11.951C11.2375 10.6383 10.5 8.85782 10.5 7.0013C10.5 8.85782 9.7625 10.6383 8.44975 11.951C7.13699 13.2638 5.35652 14.0013 3.5 14.0013C5.35652 14.0013 7.13699 14.7388 8.44975 16.0516C9.7625 17.3643 10.5 19.1448 10.5 21.0013Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4007_32977">
            <rect width="28" height="28" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        {url
          ? <div className={`relative w-[720px] max-h-[340px] bg-cover bg-center flex justify-center ${clickedPreview && ''}`} style={{ background: `url(${thumbnail ? thumbnailDefault : thumbnailAudioDefault})` }}>
                <ReactPlayer
                  onClickPreview={() => setClickedPreview(true)}
                  url={url}
                  width={720}
                  height={340}
                  light={
                    <div className="w-full h-full relative flex justify-center">
                      <img src={thumbnail || thumbnailAudioDefault} alt="Thumbnail" className='max-h-[340px] max-w-[720px] object-cover' />
                      <button className='absolute z-10' style={{ left: 'calc(50% - 49px)', top: 'calc(50% - 53px)' }}>
                        <svg width="98" height="106" viewBox="0 0 98 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d_3792_25601)">
                          <ellipse cx="48.5675" cy="53.0264" rx="38.419" ry="42.501" fill="white"/>
                          <path d="M67.4327 52.7066C67.6186 52.8728 67.6186 53.1638 67.4327 53.33L39.6638 78.1601C39.3944 78.4009 38.967 78.2097 38.967 77.8484L38.967 28.1882C38.967 27.8268 39.3944 27.6356 39.6638 27.8765L67.4327 52.7066Z" fill="black"/>
                          </g>
                          <defs>
                          <filter id="filter0_d_3792_25601" x="0.113372" y="0.490325" width="96.91" height="105.072" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feMorphology radius="3.34502" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3792_25601"/>
                          <feOffset/>
                          <feGaussianBlur stdDeviation="3.34502"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3792_25601"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3792_25601" result="shape"/>
                          </filter>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  }
                  controls
                />
              </div>
          : <div className='absolute left-1/2'><svg className='w-16 h-16' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg></div>
          }

        <div className='my-[8px]'>
          <Timeline />
        </div>

        <div className='pb-[8px] border-b border-b-[#e4e4e4]' ref={quotesRef}>
          <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Key Quotes</p>
        </div>

        <Quotes />

        <div className='pb-[8px] border-b border-b-[#e4e4e4]' ref={problemsRef}>
          <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Problems</p>
        </div>

        <Problems />

        <div className='pb-[8px] border-b border-b-[#e4e4e4]' ref={needsRef}>
          <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Needs</p>
        </div>

        <Needs />

        <QAs />

        <div className='pb-[8px] border-b border-b-[#e4e4e4]' ref={feedbackRef}>
          <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Feedbacks</p>
        </div>

        <Feedbacks />
      </div>
    </div>
  )
}
