import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MeetingProvider } from '../../utils/meetingProvider'
import { customFetch, getMeetingProvider } from '../../../utils'
import MultiTag from './multiTag'
import cnt from '../../../constants'
import { ProjectContext } from '../../../context/ProjectContext'
import { formatDate } from '../../../utils/formatDate'
import toast from 'react-hot-toast'

export function EditNoteDialog ({ note, meeting, close, changeNoteContent, changeNoteCategory, changeNoteColor, deleteNote }) {
  const { project, setNotes, categories } = useContext(ProjectContext)
  const [content, setContent] = useState(note.content)
  const noteContentRef = useRef(null)

  useEffect(() => {
    const textArea = noteContentRef.current
    if (textArea) {
      textArea.focus()
      textArea.setSelectionRange(textArea.value.length, textArea.value.length)
    }
  }, [])

  async function handleToggleStar () {
    try {
      await customFetch('/updateNoteStar', 'PUT', { noteId: note.id, star: !note.star })
      setNotes(prevNotes => prevNotes.map(n => n.id === note.id ? { ...n, star: !n.star } : n))
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black" onClick={close}>
      {/* <div className="w-[570px] h-[334px] px-[34px] py-8 bg-white rounded-2xl border-2 border-red-700 justify-center items-center inline-flex"> */}
      {/* <div className="w-[1400px] h-[907px] relative bg-white rounded-2xl"> */}
      {/* <div onClick={(e) => e.stopPropagation()} className="absolute top-[10%] left-[10%] w-[80%] h-[80%] px-[34px] py-8 bg-white rounded-2xl"> */}
      <div onClick={(e) => e.stopPropagation()} className="absolute top-[10%] left-[10%] w-[80%] h-[80%] bg-white rounded-2xl">
        <div className='h-full flex'>
          <div className='grow pl-[34px] pt-[24px] bg-grey-100 rounded-2xl'>
            <p className="text-[#44566C] text-4xl font-normal font-['DM_Sans'] leading-[41.40px]">Insight #23</p>
            <div className='flex flex-col items-center justify-center' style={{ height: 'calc(100% - 65px)' }}>
              <textarea
                ref={noteContentRef}
                style={{ resize: 'none', backgroundColor: note.color }}
                className={`focus:outline-none focus:ring-1 focus:ring-[#CF3F75] focus:shadow-xl w-[532.28px] h-[317px] py-[47.31px] px-[44.89px] justify-center items-center rounded-[9.46px] shadow text-[#40424A] font-semibold font-['Manrope'] hideScrollbar ${content.length < 120 ? 'text-[32px] leading-[48px] tracking-wider' : 'text-[24px] leading-[40px] tracking-wide'}`}
                // className="w-3/4 h-12 pl-4 pr-8 py-2.5 shadow-inner border border-grey-200 bg-grey-100 rounded text-zinc text-base font-medium font-['Manrope']"
                placeholder="Choose note content"
                value={content}
                onChange={(e) => {
                  setContent(e.target.value)
                  changeNoteContent(e.target.value)
                }}
                onKeyDown={(e) => e.key === 'Enter' && close()}
              />
            </div>
          </div>
          <div className='w-[352px] pl-[24px] py-[24px] pr-[39px] border-l-2 border-[#D3D3D6] overflow-auto hideScrollbar'>
            <div className='pb-[15px] border-b border-[#D3D3D6] relative'>
              <p className="text-medSlate text-[22px] font-medium font-['DM_Sans'] leading-relaxed">INSIGHT DETAILS</p>
              <button className='absolute bottom-[20px] right-0' onClick={close}>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 12L12 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12 12L24 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>

            <div className='flex items-center gap-[10px] py-[15px] border-b border-[#D3D3D6]'>
              <button className="grow w-[64.75px] h-[38px] p-[7px] bg-white rounded shadow border border-[#86858F] flex-col items-center gap-2.5 inline-flex" onClick={handleToggleStar}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill={note.star ? '#FFC921' : 'none'} xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1983_5083)">
                  <path d="M12.3748 17.75L6.20284 20.995L7.38184 14.122L2.38184 9.25495L9.28184 8.25495L12.3678 2.00195L15.4538 8.25495L22.3538 9.25495L17.3538 14.122L18.5328 20.995L12.3748 17.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1983_5083">
                  <rect width="24" height="24" fill="white" transform="translate(0.375)"/>
                  </clipPath>
                  </defs>
                </svg>
              </button>
              {/* <div className="grow w-[64.75px] h-[38px] p-[7px] bg-white rounded shadow border border-[#86858F] flex-col items-center gap-2.5 inline-flex">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1983_5078)">
                  <path d="M8.125 9H7.125C6.59457 9 6.08586 9.21071 5.71079 9.58579C5.33571 9.96086 5.125 10.4696 5.125 11V19C5.125 19.5304 5.33571 20.0391 5.71079 20.4142C6.08586 20.7893 6.59457 21 7.125 21H17.125C17.6554 21 18.1641 20.7893 18.5392 20.4142C18.9143 20.0391 19.125 19.5304 19.125 19V11C19.125 10.4696 18.9143 9.96086 18.5392 9.58579C18.1641 9.21071 17.6554 9 17.125 9H16.125" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12.125 14V3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9.125 6L12.125 3L15.125 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1983_5078">
                  <rect width="24" height="24" fill="white" transform="translate(0.125)"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="grow w-[64.75px] h-[38px] p-[7px] bg-white rounded shadow border border-[#86858F] flex-col items-center gap-2.5 inline-flex">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1983_5061)">
                  <path d="M7.875 9.667C7.875 8.95967 8.15599 8.28131 8.65615 7.78115C9.15631 7.28099 9.83467 7 10.542 7H19.208C19.5582 7 19.905 7.06898 20.2286 7.20301C20.5522 7.33704 20.8462 7.53349 21.0939 7.78115C21.3415 8.0288 21.538 8.32281 21.672 8.64638C21.806 8.96996 21.875 9.31676 21.875 9.667V18.333C21.875 18.6832 21.806 19.03 21.672 19.3536C21.538 19.6772 21.3415 19.9712 21.0939 20.2189C20.8462 20.4665 20.5522 20.663 20.2286 20.797C19.905 20.931 19.5582 21 19.208 21H10.542C10.1918 21 9.84496 20.931 9.52138 20.797C9.19781 20.663 8.9038 20.4665 8.65615 20.2189C8.40849 19.9712 8.21204 19.6772 8.07801 19.3536C7.94398 19.03 7.875 18.6832 7.875 18.333V9.667Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.887 16.737C4.58034 16.5622 4.32527 16.3095 4.14758 16.0045C3.96988 15.6995 3.87585 15.353 3.875 15V5C3.875 3.9 4.775 3 5.875 3H15.875C16.625 3 17.033 3.385 17.375 4" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1983_5061">
                  <rect width="24" height="24" fill="white" transform="translate(0.875)"/>
                  </clipPath>
                  </defs>
                </svg>
              </div> */}
              <button className="grow w-[64.75px] h-[38px] p-[7px] bg-white rounded shadow border border-[#86858F] flex-col items-center gap-2.5 inline-flex" onClick={deleteNote}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1982_5440)">
                  <path d="M4.625 7H20.625" stroke="#D40814" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10.625 11V17" stroke="#D40814" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14.625 11V17" stroke="#D40814" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M5.625 7L6.625 19C6.625 19.5304 6.83571 20.0391 7.21079 20.4142C7.58586 20.7893 8.09457 21 8.625 21H16.625C17.1554 21 17.6641 20.7893 18.0392 20.4142C18.4143 20.0391 18.625 19.5304 18.625 19L19.625 7" stroke="#D40814" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9.625 7V4C9.625 3.73478 9.73036 3.48043 9.91789 3.29289C10.1054 3.10536 10.3598 3 10.625 3H14.625C14.8902 3 15.1446 3.10536 15.3321 3.29289C15.5196 3.48043 15.625 3.73478 15.625 4V7" stroke="#D40814" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1982_5440">
                  <rect width="24" height="24" fill="white" transform="translate(0.625)"/>
                  </clipPath>
                  </defs>
                </svg>
              </button>
            </div>

            <div className='py-[15px] border-b border-[#D3D3D6]'>
              <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Note Color</p>
              <div className='relative'>
                <div className='absolute left-0 top-[2px]'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="9" fill={note.color} stroke="#505053" strokeWidth="2"/>
                  </svg>
                </div>
                <select className="pl-6 w-full rounded text-[#44566C] text-lg font-bold font-['Manrope'] leading-tight tracking-wide capitalize cursor-pointer" onChange={(e) => changeNoteColor(e.target.value)} value={note.color}>
                    {Object.keys(cnt.colors).map((color) => (
                      <option key={color} value={cnt.colors[color]} className='capitalize'>{color}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className='py-[15px] border-b border-[#D3D3D6]'>
              <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Created By</p>
              <p className="text-[#44566C] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{note.created_by}</p>
            </div>

            <div className='py-[15px] border-b border-[#D3D3D6]'>
              <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Created</p>
              <p className="text-[#62718D] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{formatDate(note.created_at)}</p>
            </div>

            <div className='py-[15px] border-b border-[#D3D3D6]'>
              <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Project</p>
              <div className='flex items-center gap-[8px]'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1982_5425)">
                  <path d="M20.25 5.25H15V11.25H20.25V5.25Z" fill="#E4E4E4"/>
                  <path d="M15 5.14258H9V14.1426H15V5.14258Z" fill="#FF4A8E"/>
                  <path opacity="0.2" d="M9 11.25H3.75V19.5C3.75 19.6989 3.82902 19.8897 3.96967 20.0303C4.11032 20.171 4.30109 20.25 4.5 20.25H8.25C8.44891 20.25 8.63968 20.171 8.78033 20.0303C8.92098 19.8897 9 19.6989 9 19.5V11.25Z" fill="#FF4A8E"/>
                  <path d="M3.75 5.25H20.25V16.5C20.25 16.6989 20.171 16.8897 20.0303 17.0303C19.8897 17.171 19.6989 17.25 19.5 17.25H15.75C15.5511 17.25 15.3603 17.171 15.2197 17.0303C15.079 16.8897 15 16.6989 15 16.5V14.25H9V19.5C9 19.6989 8.92098 19.8897 8.78033 20.0303C8.63968 20.171 8.44891 20.25 8.25 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V5.25Z" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 11.25H3.75" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 5.25V14.25" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15 11.25H20.25" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15 5.25V14.25" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1982_5425">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                <p className="text-[#40424A] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{project.name}</p>
              </div>
            </div>

            <div className='py-[15px] border-b border-[#D3D3D6]'>
              <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Category</p>
              <select className="w-full rounded text-[#44566C] text-lg font-bold font-['Manrope'] leading-tight tracking-wide cursor-pointer" onChange={(e) => changeNoteCategory(e.target.value)} value={note.category_id}>
                {categories.map((category) => <option key={category.id} value={category.id}>{category.content}</option>)}
              </select>
            </div>

            <div className='py-[15px] border-b border-[#D3D3D6]'>
              <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Tags</p>
              <MultiTag note={note} />
            </div>

            <div className='py-[15px] border-b border-[#D3D3D6]'>
              <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Session</p>
              <div className='flex items-center gap-[8px]'>
                <MeetingProvider provider={!meeting.thread_id ? 'custom' : getMeetingProvider(meeting.meeting_url)} />
                <p className="text-[#40424A] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{meeting.name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EditNoteDialog.propTypes = {
  note: PropTypes.object.isRequired,
  meeting: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  changeNoteContent: PropTypes.func.isRequired,
  changeNoteCategory: PropTypes.func.isRequired,
  changeNoteColor: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired
}
