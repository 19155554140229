/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import PropTypes from 'prop-types'

export function Category ({ cat }) {
  const {
    setGroupNotesBy,
    setScrollToCategory,
    handleDeleteCategory,
    handleRenameCategory,
    categoryCreated
  } = useContext(ProjectContext)
  const [isHovered, setIsHovered] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const categoryRef = useRef(null)

  useEffect(() => {
    if (cat.id === categoryCreated) {
      setIsEditing(true)
    }
  }, [categoryCreated])

  return (
    <div
      onClick={() => {
        setGroupNotesBy('category')
        setScrollToCategory(cat.id)
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`py-3 cursor-pointer ${cat.content === 'Misc' && 'border-t-2 border-t-grey-200'}`}
    >
      <div className='flex justify-between items-center'>
        <div className='flex items-center'>
            <div className='w-[22px] h-[22px]'>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.1266 1L1 6L11.1266 11L21.2532 6L11.1266 1Z" stroke="#FFC921" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1 11L11.1266 16L21.2532 11" stroke="#FFC921" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1 16L11.1266 21L21.2532 16" stroke="#FFC921" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            {isEditing
              ? (
              <input
                ref={categoryRef}
                className="ml-4 text-zinc text-base font-semibold font-['Manrope'] leading-tight"
                type="text"
                value={cat.content}
                onChange={e => handleRenameCategory(e.target.value, cat)}
                onKeyDown={e => e.key === 'Enter' && categoryRef.current.blur()}
                onBlur={() => setIsEditing(false)}
                autoFocus
              />
                )
              : (
              <p
                className="ml-4 text-zinc text-base font-semibold font-['Manrope'] leading-tight"
                onDoubleClick={(e) => {
                  if (cat.content === 'All Notes' || cat.content === 'Uncategorized') return
                  e.stopPropagation()
                  setIsEditing(true)
                }}
              >
                {cat.content}
              </p>
                )}
          </div>
          <div className='flex items-center'>
            <div className="mr-2 h-5 p-2 bg-slate rounded-[30px] flex justify-center items-center">
              <p className="text-white text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
                {cat.amount}
              </p>
            </div>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14" cy="14" r="10.5" transform="rotate(-90 14 14)" fill="#EBEBEB"/>
              <path d="M12.834 10.5L16.334 14L12.834 17.5" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
      </div>
      {isHovered && cat.content !== 'All Notes' && cat.content !== 'Uncategorized' && (
        <div className='ml-8 mt-2 flex items-center'>
          <button
            onClick={(e) => {
              e.stopPropagation()
              setIsEditing(true)
            }}
            className='px-2 py-1 bg-white rounded-[30px] shadow border border-neutral-200 flex justify-center items-center gap-[3px]'
          >
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2324_5552)">
            <path d="M3.5 4H3C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5V9.5C2 9.76522 2.10536 10.0196 2.29289 10.2071C2.48043 10.3946 2.73478 10.5 3 10.5H7.5C7.76522 10.5 8.01957 10.3946 8.20711 10.2071C8.39464 10.0196 8.5 9.76522 8.5 9.5V9" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.1925 3.79255C10.3894 3.59563 10.5001 3.32855 10.5001 3.05005C10.5001 2.77156 10.3894 2.50448 10.1925 2.30755C9.99558 2.11063 9.72849 2 9.45 2C9.17151 2 8.90442 2.11063 8.7075 2.30755L4.5 6.50005V8.00005H6L10.1925 3.79255Z" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 3L9.5 4.5" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_2324_5552">
            <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">Rename</p>
          </button>
          <button
            onClick={() => handleDeleteCategory(cat)}
            className='ml-2 px-2 py-1 bg-white rounded-[30px] shadow border border-neutral-200 flex justify-center items-center gap-[3px]'
          >
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2324_5559)">
            <path d="M2 4H10" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 6V9" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 6V9" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 4L3 10C3 10.2652 3.10536 10.5196 3.29289 10.7071C3.48043 10.8946 3.73478 11 4 11H8C8.26522 11 8.51957 10.8946 8.70711 10.7071C8.89464 10.5196 9 10.2652 9 10L9.5 4" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.5 4V2.5C4.5 2.36739 4.55268 2.24021 4.64645 2.14645C4.74021 2.05268 4.86739 2 5 2H7C7.13261 2 7.25979 2.05268 7.35355 2.14645C7.44732 2.24021 7.5 2.36739 7.5 2.5V4" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_2324_5559">
            <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">Delete</p>
          </button>
        </div>
      )}
    </div>
  )
}

Category.propTypes = {
  cat: PropTypes.object.isRequired
}
