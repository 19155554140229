import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { MeetingProvider } from '../../../utils/meetingProvider'
import { formatFileDuration, getDayAndMonth, getHour, getMeetingProvider } from '../../../../utils'
import Tippy from '@tippyjs/react'
import { ProjectContext } from '../../../../context/ProjectContext'

export function MeetingInfo ({ meeting, isExpanded, getToggleProps }) {
  const { handleRenameMeeting } = useContext(ProjectContext)
  const meetingNameRef = useRef(null)

  return (
      <>
        <div key={meeting.id} className='flex items-center'>
          <input
            ref={meetingNameRef}
            className={`mb-2 bg-grey-100 text-zinc text-lg font-semibold font-['Manrope'] leading-tight tracking-wide ${!meeting.isLive && !meeting.isPaused && 'w-full'}`}
            type="text"
            placeholder='Unnamed Meeting.'
            value={meeting.name}
            onChange={e => handleRenameMeeting(e.target.value, meeting.id)}
            onKeyDown={e => e.key === 'Enter' && meetingNameRef.current.blur()}
          />
          {/* <p
            // className={`mb-2 bg-grey-100 text-zinc text-lg font-semibold font-['Manrope'] leading-tight tracking-wide ${!meeting.isLive && !meeting.isPaused && 'w-full'}`}
            className="mb-2 bg-grey-100 text-zinc text-lg font-semibold font-['Manrope'] leading-tight tracking-wide"
          >
            {meeting.name}
          </p> */}
          {meeting.isLive && (
            <div className="mb-2 ml-[10px] h-[24px] p-2 bg-red rounded shadow flex justify-center items-center">
              <p className="text-white text-xs font-extrabold font-['Manrope'] tracking-wide">LIVE</p>
            </div>
          )}
          {meeting.isPaused && (
            <Tippy
              interactive={true}
              maxWidth={350}
              content={
                <div className='p-2 w-[300px] text-left'>
                  <span>This meeting has been paused due to insufficient minutes. </span>
                  <button className='text-linkBlue font-semibold' onClick={() => window.open('https://www.usermuse.ai/pricing/', '_blank')}>UPGRADE</button>
                </div>
              }
            >
              <div className="ml-[10px] w-[84px] h-5 p-2 bg-[#40424A] rounded justify-center items-center gap-[3px] inline-flex">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_3577_1281)">
                  <path d="M4.04036 2.66797H3.3737C3.19689 2.66797 3.02732 2.78211 2.90229 2.98527C2.77727 3.18843 2.70703 3.46398 2.70703 3.7513V10.2513C2.70703 10.5386 2.77727 10.8142 2.90229 11.0173C3.02732 11.2205 3.19689 11.3346 3.3737 11.3346H4.04036C4.21718 11.3346 4.38674 11.2205 4.51177 11.0173C4.63679 10.8142 4.70703 10.5386 4.70703 10.2513V3.7513C4.70703 3.46398 4.63679 3.18843 4.51177 2.98527C4.38674 2.78211 4.21718 2.66797 4.04036 2.66797Z" fill="white"/>
                  <path d="M8.3763 2.66797H7.70964C7.53282 2.66797 7.36326 2.78211 7.23823 2.98527C7.11321 3.18843 7.04297 3.46398 7.04297 3.7513V10.2513C7.04297 10.5386 7.11321 10.8142 7.23823 11.0173C7.36326 11.2205 7.53282 11.3346 7.70964 11.3346H8.3763C8.55311 11.3346 8.72268 11.2205 8.84771 11.0173C8.97273 10.8142 9.04297 10.5386 9.04297 10.2513V3.7513C9.04297 3.46398 8.97273 3.18843 8.84771 2.98527C8.72268 2.78211 8.55311 2.66797 8.3763 2.66797Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_3577_1281">
                  <rect width="13" height="13" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                  </defs>
                </svg>
                <p className="text-white text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Paused</p>
              </div>
            </Tippy>
          )}
          <div className='grow flex justify-end'>
            <button {...getToggleProps()}>
              {!isExpanded
                ? (
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1947_10897)">
                  <circle cx="24" cy="24" r="18" fill="#EBEBEB"/>
                  <path d="M30 22L24 28L18 22" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1947_10897">
                  <rect width="48" height="48" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                  )
                : (
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_2056_853)">
                  <circle cx="18" cy="18" r="18" transform="matrix(1 0 0 -1 6 42)" fill="#EBEBEB"/>
                  <path d="M30 26L24 20L18 26" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_2056_853">
                  <rect width="48" height="48" fill="white" transform="matrix(1 0 0 -1 0 48)"/>
                  </clipPath>
                  </defs>
                </svg>
                  )}
            </button>
          </div>
        </div>
        <div className='flex items-center'>
          {meeting.size
            ? <p>{Math.round(meeting.size / 1048576)}MB・</p>
            : !meeting.start
                ? (
              <>
                <p>{getDayAndMonth(new Date(meeting.created_at))}・</p>
                <p>{getHour(new Date(meeting.created_at))}・</p>
              </>
                  )
                : (
              <>
                <p>{getDayAndMonth(new Date(meeting.start))}・</p>
                <p>{getHour(new Date(meeting.start))}・</p>
              </>
                  )
          }
          {!!meeting.duration && (
            <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1479_4144)">
              <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 7V12L15 15" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_1479_4144">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <p>&nbsp;{formatFileDuration(meeting.duration)}・</p>
            </>
          )}
          <MeetingProvider provider={!meeting.stored_name && !meeting.meeting_url ? 'custom' : getMeetingProvider(meeting.meeting_url)} />
        </div>
      </>
  )
}

MeetingInfo.propTypes = {
  meeting: PropTypes.object,
  isExpanded: PropTypes.bool,
  getToggleProps: PropTypes.any
}
