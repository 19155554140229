import React, { useContext, useState } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { MeetingInfo } from './meetingInfo'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { DeleteMeetingDialog } from './deleteMeetingDialog'

export function Session ({ meeting }) {
  const { selectedMeeting, setSelectedMeeting, setScrollToMeeting, setGroupNotesBy, notes, transcripts } = useContext(ProjectContext)
  const [isHovered, setIsHovered] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  return (
    <div
      className={`cursor-pointer border-b-2 border-b-grey-200 ${selectedMeeting === meeting.id && 'bg-hoverPink -mx-6 px-6 border-x-4 border-x-pink'}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div onClick={() => {
        setGroupNotesBy('meeting')
        setScrollToMeeting(meeting.id)
        setSelectedMeeting(meeting.id)
      }}>
        <MeetingInfo meeting={meeting} isEditing={isEditing} setIsEditing={setIsEditing} />
      </div>
      {isHovered && (
        <div className='ml-8 -mt-2 mb-4 flex items-center'>
          <button
            onClick={() => {
              setGroupNotesBy('meeting')
              setScrollToMeeting(meeting.id)
              setSelectedMeeting(meeting.id)
              setIsEditing(true)
            }}
            className='px-2 py-1 bg-white rounded-[30px] shadow border border-neutral-200 flex justify-center items-center gap-[3px]'
          >
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2324_5552)">
            <path d="M3.5 4H3C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5V9.5C2 9.76522 2.10536 10.0196 2.29289 10.2071C2.48043 10.3946 2.73478 10.5 3 10.5H7.5C7.76522 10.5 8.01957 10.3946 8.20711 10.2071C8.39464 10.0196 8.5 9.76522 8.5 9.5V9" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.1925 3.79255C10.3894 3.59563 10.5001 3.32855 10.5001 3.05005C10.5001 2.77156 10.3894 2.50448 10.1925 2.30755C9.99558 2.11063 9.72849 2 9.45 2C9.17151 2 8.90442 2.11063 8.7075 2.30755L4.5 6.50005V8.00005H6L10.1925 3.79255Z" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 3L9.5 4.5" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_2324_5552">
            <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">Rename</p>
          </button>
          <button onClick={() => setShowDeleteDialog(true)} className='ml-2 px-2 py-1 bg-white rounded-[30px] shadow border border-neutral-200 flex justify-center items-center gap-[3px]'>
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2324_5559)">
            <path d="M2 4H10" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 6V9" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 6V9" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 4L3 10C3 10.2652 3.10536 10.5196 3.29289 10.7071C3.48043 10.8946 3.73478 11 4 11H8C8.26522 11 8.51957 10.8946 8.70711 10.7071C8.89464 10.5196 9 10.2652 9 10L9.5 4" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.5 4V2.5C4.5 2.36739 4.55268 2.24021 4.64645 2.14645C4.74021 2.05268 4.86739 2 5 2H7C7.13261 2 7.25979 2.05268 7.35355 2.14645C7.44732 2.24021 7.5 2.36739 7.5 2.5V4" stroke="#D40814" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_2324_5559">
            <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">Delete</p>
          </button>
          {showDeleteDialog && createPortal(
            <DeleteMeetingDialog meetingId={meeting.id} transcriptsCount={transcripts.findIndex(transcript => transcript.meeting_id === meeting.id) !== -1 ? 1 : 0} notesCount={notes.filter(note => note.meeting_id === meeting.id).length} handleClose={() => setShowDeleteDialog(false)} />,
            document.body
          )}
        </div>
      )}
    </div>
  )
}

Session.propTypes = {
  meeting: PropTypes.object.isRequired
}
